body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

#login {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
}

.MuiStepIcon-active {
  border-radius: 12px;
  box-shadow: 0 0 0 0 #2096f8;
  animation: pulse 1s infinite;
}

.MuiStepConnector-vertical {
  padding: 4px 0 !important;
}

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}